.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* src/App.css */

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.App {
  color: #333;
}

header {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
}

header a {
  color: #333;
}

header a:hover {
  color: #0070f3;
}

header button {
  background-color: #0070f3;
}

header button:hover {
  background-color: #005bb5;
}

section {
  padding: 60px 0;
}

.bg-lightblue-50 {
  background-color: #e0f7fa;
}

h1, h2, h3, h4, h5, h6 {
  color: #0070f3;
}

button {
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #005bb5;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.fixed-telegram {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  z-index: 1000;
}

.fixed-telegram img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
}

.fixed-telegram img:hover {
  transform: scale(1.1);
}

.myheader {
  background-color: #3D3D3D;
  font-size: 20px;
}

.myBtn {
  background-color: #467CB7;
}

.myNav {
  color: white;
  font-weight: bold;
}

.mockup {
  margin: 0;
}

.case-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.case-container h3 {
  font-size: 24px;
  color: black;
}

.myH {
  font-weight: 500;
}

.myh1 {
  font-weight: 500;
}

.myP {
  display: flex;
  margin-bottom: 50px;
}

.myExample {
  margin-bottom: 20px;
  background-color: #3D3D3D;
  color: white;
  transition: transform 0.3s;
}

.myExample:hover {
  transform: scale(1.1);
}

.modalBtn {
  margin-left: 20px;
}

.phone {
  color: white;
}

.header-column {
  display: flex;
  width: 100%;
}

.myHeaderContainer {
  width: 70%;
}

@media (max-width: 768px) {
  header .container {
    flex-direction: column;
  }

  .case-section {
    flex-direction: column;
  }

  .case-container {
    margin-left: 0;
    width: 100%;
  }

  .fisrtsection {
    padding: 40px 0;
    margin-top: 140px;
  }

  .mockup {
    height: auto;
    width: 100%;
  }

  .header-column {
    flex-direction: column;
  }

  .header-row {
    display: flex;
    width: 100%;
  }

  .secondrowHeader {
    margin-top: 10px;
  }

  .myHeaderContainer {
    width: 100%;
  }

  .myheader {
    font-size: 1rem;
  }
}

